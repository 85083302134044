const level = localStorage.getItem("Ulevel");
if (level == 2) {
  listMenu = [
    //------------------------------------------- ADMIN -------------------------------------
    {
      title: "Dashboard",
      route: "dashboard_admin",
      icon: "GridIcon",
    },
    {
      title: "Approval Akun",
      route: "list_approval_akun",
      icon: "CheckCircleIcon",
    },
    {
      title: "Master Data",
      icon: "ListIcon",
      // tag: '2',
      // tagVariant: 'light-warning',
      children: [
        {
          title: "List Kategori",
          route: "list_category",
        },
        {
          title: "List Peserta",
          route: "list_student",
        },
        {
          title: "List Dosen",
          route: "list_teacher",
        },
        // {
        //   title: "List Pelajaran",
        //   route: "list_study",
        // },
        {
          title: "List Tinjauan 1",
          route: "list_tinjauan_satu",
        },
        {
          title: "List Tinjauan 2",
          route: "list_tinjauan_dua",
        },
        {
          title: "List Tinjauan 3",
          route: "list_tinjauan_tiga",
        },
        {
          title: "List Tinjauan 4",
          route: "list_tinjauan_empat",
        },
        {
          title: "List Tinjauan 5",
          route: "list_tinjauan_lima",
        },
        {
          title: "List Tinjauan 6",
          route: "list_tinjauan_enam",
        },
        {
          title: "List Tinjauan 7",
          route: "list_tinjauan_tujuh",
        },
        {
          title: "List Tinjauan 8",
          route: "list_tinjauan_delapan",
        },
        {
          title: "List Tinjauan 9",
          route: "list_tinjauan_sembilan",
        },
        {
          title: "List Kampus",
          route: "list_collage",
        },
        {
          title: "List Diskon",
          route: "list_discount",
        },
      ],
    },
    {
      title: "BIMBEL",
      route: "list_bimbel",
      icon: "YoutubeIcon",
    },
    {
      title: "OSCE",
      route: "list_osce",
      icon: "YoutubeIcon",
    },
    {
      title: "Tryout",
      icon: "EditIcon",
      children: [
        {
          title: "Event Tryout",
          route: "list_event",
        },
        {
          title: "Jadwal Tryout",
          route: "list_plan",
        },
      ],
    },
    {
      title: "Bank Soal",
      route: "list_question",
      icon: "ServerIcon",
    },
    {
      title: "Artikel",
      route: "list_blog",
      icon: "BookOpenIcon",
    },
    {
      title: "Paket",
      route: "list_paket",
      icon: "PackageIcon",
    },
    {
      title: "Produk",
      route: "list_product",
      icon: "BoxIcon",
    },
    {
      title: "Laporan",
      icon: "PieChartIcon",
      children: [
        {
          title: "Pembayaran",
          route: "list_pembayaran_admin",
        },
        // {
        //   title: "Hasil Tryout",
        //   route: "list_result_admin",
        // },
        // {
        //   title: "Analisa Tryout",
        //   route: "list_analysis_admin",
        // },
      ],
    },
    {
      title: "Akun Saya",
      route: "profile",
      icon: "UserIcon",
    },
    {
      title: "Setting",
      icon: "SettingsIcon",
      children: [
        {
          title: "Banner",
          route: "list_banner",
        },
        {
          title: "Fitur & Aktivitas",
          route: "fitur_activity",
        },
        {
          title: "Kontak",
          route: "list_contact",
        },
      ],
    },
    {
      title: "Ke Halaman Depan",
      route: "home",
      icon: "HomeIcon",
    },
  ];
}

if (level == 0 ) {
  var listMenu = [
    //------------------------------------------- USER -------------------------------------
    {
      title: "Dashboard",
      route: "dashboard_user",
      icon: "GridIcon",
    },
    // {
    //   title: "Bimbel",
    //   route: "bimbel_user",
    //   icon: "BookOpenIcon",
    // },
    {
      title: "TryOut",
      route: "list_tryout",
      icon: "EditIcon",
    },
    // {
    //   title: "OSCE",
    //   route: "osce_user",
    //   icon: "FileTextIcon",
    // },
    {
      title: "Upgrade Akun",
      route: "upgrade_akun",
      icon: "ArrowUpCircleIcon",
    },
    {
      title: "Laporan",
      icon: "PieChartIcon",
      children: [
        {
          title: "Pembayaran",
          route: "list_pembayaran_user",
        },
        {
          title: "Hasil Tryout",
          route: "list_hasil_tryout_user",
        }
      ],
    },
    {
      title: "Akun Saya",
      route: "profile",
      icon: "UserIcon",
    },
    {
      title: "Ke Halaman Depan",
      route: "home",
      icon: "HomeIcon",
    },
  ];
}

if (level == 1) {
  var listMenu = [
    //------------------------------------------- USER -------------------------------------
    {
      title: "Dashboard",
      route: "dashboard_user",
      icon: "GridIcon",
    },
    {
      title: "Bimbel",
      route: "bimbel_user",
      icon: "BookOpenIcon",
    },
    {
      title: "TryOut",
      route: "list_tryout",
      icon: "EditIcon",
    },
    // {
    //   title: "OSCE",
    //   route: "osce_user",
    //   icon: "FileTextIcon",
    // },
    {
      title: "Upgrade Akun",
      route: "upgrade_akun",
      icon: "ArrowUpCircleIcon",
    },
    {
      title: "Laporan",
      icon: "PieChartIcon",
      children: [
        {
          title: "Pembayaran",
          route: "list_pembayaran_user",
        },
        {
          title: "Hasil Tryout",
          route: "list_hasil_tryout_user",
        }
      ],
    },
    {
      title: "Akun Saya",
      route: "profile",
      icon: "UserIcon",
    },
    {
      title: "Ke Halaman Depan",
      route: "home",
      icon: "HomeIcon",
    },
  ];
}

export default listMenu;
