<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">Hi, {{ nama }}</p>
        <span class="user-status">{{ email }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      v-if="level == 2"
      :to="{ name: 'profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Akun Saya</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-else
      :to="{ name: 'profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Akun Saya</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";

export default {
  components: {},
  data() {
    return {
      avatarText,
      nama: '',
      email: '',
      level:''
    };
  },
  created: function () {
    this.checkAuth()
    // console.log("App version: " + process.env.VUE_APP_SERVICE_URL);
    // console.log("Environment: " + process.env.NODE_ENV);
  },
  methods: {
    checkAuth() {
      // CALLBACK
      const token = localStorage.getItem("token");
      if (!token) {
        return router.push({
          name: "login",
        });
      }
      this.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "user")
        .then((response) => {
          if (response.data != null) {
            this.cek_user = response;
            this.nama = response.data.nama;
            this.email = response.data.email;
            this.level = response.data.level;
}
        });
    },
    logout() {
      // logout with auth
      localStorage.removeItem("Unama");
      localStorage.removeItem("Ulevel");
      localStorage.removeItem("Uemail");
      localStorage.removeItem("Ukampus");
      localStorage.removeItem("Uid");
      localStorage.removeItem("token");
      this.$router.go();
      const token = localStorage.getItem("token");
      this.$http.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "logout")
        .then((res) => {
          if (res.data.success == true) {
            // removing localstorage token
            localStorage.removeItem("token");
            // redirect to login page
            this.$router.push({ name: "login" });
          }
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    },
  },
};
</script>
